import { Button, Drawer, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import ActivityDetailsTab from './drawer/ActivityDetailsTab';
import LinksTab from './drawer/LinksTab';
import NeedsTab from './drawer/NeedsTab';
import { canEditAnActivity } from '../../redux/slices/app/activity.slice';
import { selectPlanningSelected } from '../../redux/slices/app/planning.slice';

const DrawerRow = styled(Row)`
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    paddingbottom: 0;
    & > * {
        flex-grow: 1;
    }
`;

const StyledDrawer = styled(Drawer)`
    h3 {
        color: #fbbe3f;
        font-size: 15px;
        font-weight: bold;
        font-family: 'Uni Sans', sans-serif;
        margin-top: 20px;
    }

    .ant-form-item {
        margin-bottom: 8px;
    }

    .ant-form-item-label > label {
        color: #828282;
        font-size: 13px;
        font-weight: bold;
        font-family: Lato, sans-serif;
    }

    .ant-input-number {
        width: 100%;
    }

    .ant-input,
    .ant-input-number-input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker {
        background: #f9f9f9;
        box-shadow: inset 2px 3px 3px rgba(130, 130, 130, 0.15);
        border-radius: 4px;
        border-color: #f3f3f3;
    }

    .ant-input.ant-input-disabled,
    .ant-input-number-disabled .ant-input-number-input,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker-disabled.ant-picker {
        background: #aaaaaa;
        color: #000;
    }

    .ant-picker-input > input[disabled] {
        color: #000;
    }
`;

const DrawerActivity = ({ drawerVisible, activity, drawerTab }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'drawer_activity' });
    const canEditActivity = useSelector(canEditAnActivity);
    const planningSelected = useSelector(selectPlanningSelected);
    const [tab, setTab] = useState('activity_details');
    const [currentTask, setCurrentTask] = useState(null);
    const [cannotEdit, setCannotEdit] = useState(true);



    useEffect(() => {
        setTab(drawerTab);
    }, [drawerTab]);

    useEffect(() => {
        setCurrentTask({
            ...activity,
            startDate: moment(activity.start_date),
            endDate: moment(activity.end_date),
        });
        setCannotEdit(!canEditActivity(activity.serverId, activity.status, activity.jobId));
    }, [activity]);


    const onClose = () => {
        window.ganttInstance.hideLightbox();
        // refreshPlanningSelected();
        window.ganttInstance.render();
    };

    const computeCannotEditHandle = (id, status, jobId) => setCannotEdit(!canEditActivity(id, status, jobId));

    return (
        currentTask && (
            <StyledDrawer
                title={
                    <span>
                        {t('activity_edit')} <label>{currentTask.text}</label>
                    </span>
                }
                width={600}
                onClose={onClose}
                placement="right"
                open={drawerVisible}
            >
                <div className="flex flex-grow flex-col h-full">
                        <DrawerRow>
                            <Button
                                type={tab === 'activity_details' ? 'primary' : 'secondary'}
                                onClick={() => setTab('activity_details')}
                                className="w-1/2"
                            >
                                {t('activity_details')}
                            </Button>
                            <Button
                                type={tab === 'links' ? 'primary' : 'secondary'}
                                onClick={() => setTab('links')}
                                className="w-1/2"
                                disabled={currentTask.children.length > 0}
                            >
                                {t('links')}
                            </Button>
                            <Button
                                type={tab === 'needs' ? 'primary' : 'secondary'}
                                onClick={() => setTab('needs')}
                                disabled={currentTask.serverId  === planningSelected?.rootActivityId}
                                className="w-1/2"
                            >
                                { `${t('needs')}`}
                            </Button>
                        </DrawerRow>
                        <div  style={{height: 'calc(100% - 32px'}}>
                            <div className="h-full">
                                {tab === 'activity_details' && (
                                    <ActivityDetailsTab
                                        activity={currentTask}
                                        cannotEdit={cannotEdit}
                                        computeCannotEdit={computeCannotEditHandle}
                                    />
                                )}
                                {tab === 'links' && <LinksTab activity={currentTask} cannotEdit={cannotEdit} />}
                                {tab === 'needs' && <NeedsTab activity={currentTask} cannotEdit={cannotEdit} />}
                            </div>
                        </div>
                </div>
            </StyledDrawer>
        )
    );
};

DrawerActivity.propTypes = {
    drawerVisible: PropTypes.bool,
    activity: PropTypes.object.isRequired,
};

DrawerActivity.defaultProps = {
    drawerVisible: false,
};

export default DrawerActivity;
